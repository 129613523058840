/** @jsx jsx */
import { jsx } from "theme-ui";

import { useEffect, useState } from "react";
import InstantSearch from "@components/InstantSearch";

interface ComponentProps {
  category: any;
  listing: string;
}

const Impacts = ({ category, listing }: ComponentProps) => {
  let defaultState = {
    type: [],
  };

  defaultState[listing] = [];

  const { title, description } = category;

  useEffect(() => {
    if (category["title"]) {
      let updates = {
        ...defaultRefinements,
      };
      updates[listing] = [category["title"]];
      setRefinements(updates);
    }
  }, []);

  const [defaultRefinements, setRefinements] = useState(defaultState);

  return (
    <InstantSearch
      refinementType={listing}
      defaultRefinement={defaultRefinements[listing]}
      isSearch={false}
      heading={title}
      description={description ? description : ""}
    />
  );
};

export default Impacts;
